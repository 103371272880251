<template>
  <div>
    <h2 style="font-size: 14px; color: #0F9750;">Select Tenure</h2>

    <!-- Dropdown for selecting tenure -->
    <v-select
        v-if="installments.length > 0"
        v-model="selectedTenure"
        :items="installments.map(installment => ({ text: `${installment.tenure} months`, value: installment }))"
        label="Select Tenure"
        outlined
        dense
        @input="onTenureSelected"
    ></v-select>

    <div v-if="selectedTenure" class="installment-tenure-payable-section">

      <p class="emiHighlightData">Monthly Installment</p>
      <p>{{monthlyInstall}}</p>
      <!--<p class="emiHighlightData">EMI Rate</p>
      <p>{{emiRate}}</p>
      <p class="emiHighlightData">EMI Charge Payable</p>
      <p>{{emiPayableCharge}}</p>-->
      <p class="emiHighlightData">Total Payable</p>
      <p>{{totalPayableCharge}}</p>
<!--         <table>-->
<!--           <tr>-->
<!--             <td></td>-->
<!--             <td></td>-->
<!--           </tr>-->
<!--           <tr>-->
<!--             <td></td>-->
<!--             <td></td>-->
<!--           </tr>-->
<!--         </table>-->
    </div>


    <!-- Display submit button only if a tenure is selected -->
    <v-btn v-if="selectedTenure" style="color: #fff" color="#0F9750" class="paynow_btn_tdr" @click="submitInstallments">Submit</v-btn>

  </div>
</template>

<script>
export default {
  props: {
    installments: Array,
    selectedBankId: Number,
  },
  data() {
    return {
      selectedTenure: null,
      monthlyInstall: null,
      emiRate: null,
      totalPayableCharge: null,
      emiPayableCharge: null,
      selectedInstallmentId: null,
      tenureOptions: [], // List of tenure options for the dropdown

    };
  },

  methods: {
    selectTenure(tenure, installmentId) {
      this.selectedTenure = tenure;
      this.selectedInstallmentId = installmentId;
      this.$emit('tenure-selected', { tenure, installmentId });
    },

    submitInstallments() {
      this.$nextTick(() => {
        console.log("*******Bank ID********");
        console.log(this.selectedBankId);
        console.log("*******Installment ID********");
        console.log(this.selectedInstallmentId);
        console.log("*******Tenure ID********");
        console.log(this.selectedTenure);

        this.$emit('submit-installments', {
          bankId: this.selectedBankId,
          tenure: this.selectedTenure,
        });
      });
    },

    // Method to reset selected tenure
    resetSelectedTenure() {
      this.selectedTenure = null;
      this.selectedInstallmentId = null;
    },

    // Method called when tenure is selected from the dropdown
    onTenureSelected(installment ) {
      // Handle any additional logic when a tenure is selected
      console.log(installment);
      this.monthlyInstall = installment.monthlyInstallment;
      this.emiRate = installment.interestRate;
      this.emiPayableCharge = installment.emiCharge;
      this.totalPayableCharge = installment.totalAmountPayable;
    },

    // Load tenure options based on the selected bank
    loadTenureOptions() {
      // Replace this with your logic to get tenure options for the selected bank
      // For example, if your installments array has a property 'tenureOptions' for each bank,
      // you can set this.tenureOptions = this.installments.find(bank => bank.id === this.selectedBankId).tenureOptions;
      // Ensure to replace 'tenureOptions' with the actual property name in your data structure.
    },
  },

  watch: {
    // Watch for changes in selectedBankId and reset selected tenure
    selectedBankId(newVal) {
      console.log('Selected Bank ID changed:', newVal);
      this.resetSelectedTenure();
      this.loadTenureOptions(); // Load tenure options for the selected bank
    },
  },

  computed: {
    // Filter installments based on selected tenure
    filteredInstallments() {
      return this.selectedTenure
          ? this.installments.filter(installment => installment.tenure === this.selectedTenure)
          : this.installments;
    },
  },

  mounted() {
    // Load initial tenure options
    this.loadTenureOptions();
  },
};
</script>

<style scoped>
/* Your styles go here */
.installment-list-container {
  max-height: 200px;
  overflow-y: auto;
}

.selected-tenure {
  background-color: #0F9750;
  cursor: pointer;
  color: #ffffff !important;
}

.installment-class {
  padding: 2px 0;
  min-height: 14px;
}

.installment-list-item-content {
  padding: 5px 2px;
  min-height: 14px;
  font-size: 14px;
}

.installment-tenure-payable-section{
  border-radius: 5px;
  border: 2px solid #0F9750;
  padding: 5px;
  margin: 4px;
  color:#000000;
}

.installment-tenure-payable-section p{
  margin:0;
}

p.emiHighlightData{
  color: #0F9750;
  font-weght:bold;
}
</style>
