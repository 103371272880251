<template>
    <div>
        <v-container fluid pa-0>
            <v-row align="center" justify="center" style="height:100vh" dense>
                <v-col cols="12" lg="6" md="6" class="fill-height d-flex flex-column justify-center align-center">
                    <v-card elevation="4" style="border-radius:10px">
                        <!-- <v-list>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-list-item-title class="maintenance">
                                        The system is is currently under maintenance.
                                    </v-list-item-title>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list> -->
                        <v-card-text>
                            <div class="maintenance">
                                The system is currently under maintenance.
                                Please try again after a few minutes.Apologies for the inconvenience.
                                
                            </div>
                        </v-card-text>
                        <v-img
                            :src="require('../assets/maintenance.jpg')"
                            alt="maintenance image"
                            elevation="5"
                            eager
                        ></v-img>
                    </v-card>
                </v-col> 
            </v-row>
        </v-container>
    </div>
</template>


<style scoped>
    .maintenance{
        font-size: 22px !important;
        color: #0b0c0b;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-style:normal;
        line-height: 28px;
        padding: 5px !important;
    }
</style>


<script>

export default {
    
    props: ['sp_return_url'],
    data: () => ({
       //data variables and objects will be here
    }),

    methods:{
        
        // backReturnUrl(){
        //     console.log(this.$props.sp_return_url);
        //    // window.location.href = this.sp_return_url;
        // }, 
    }
}
</script>