const pg_url = require('../../components/../pg_url');
import axios from "axios";
export default {

    data: () => ({
        unauthorized_message :'Authentication problem',
        mixin_base_url: pg_url.base_url,
        isTokenValidNow:false,
    }),

    created(){
      //this.IsTokenValid();
    },

    methods: {

      IsTokenValid() {
        console.log("Check token from mixin");

        var sp_token = this.$route.query.token;
        
        if(typeof(sp_token)==='undefined'){
          this.isTokenValidNow = false;
        }

        else{
          const options = {
            method: 'POST',
            url: this.mixin_base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+sp_token
            }
          };
          axios.request(options).then(response =>{
              if(response.data.message== 'ok'){
                this.isTokenValidNow = true;
              }

              else if(response.data.sp_code== '1064'){
                this.isTokenValidNow = false;
              }
            
          }).catch(error =>{
              if (error.response.status == 401) {
                this.isTokenValidNow = false;
              }
              else{
                this.isTokenValidNow = false;
              }

          });

        }

      }










    }


  };