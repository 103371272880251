<template>
    <div style=" margin-top: 10px; margin-bottom: 13px;">

        <v-row justify="center">
            <!-- <v-dialog v-model="dialog" scrollable max-width="420px" persistent> -->
                <v-card v-if="saved_cards">
                    <v-card-text style="height:400px;">
                        <v-list subheader two-line>

                        <!-- Fetch all the saved card information of specific user -->
                        <v-list-item v-for="(card, index) in cardList" :key="index">
                             <v-avatar tile>
                                <span>
                                    <v-img :src="masterCard" width="40%" height="auto" v-if="card.card_name=='brac-master'"></v-img>
                                    <v-img :src="visaCard" width="40%" height="auto" v-if="card.card_name=='brac-visa'"></v-img>
                                    <v-img :src="amex" width="40%" height="auto" v-if="card.card_name=='american-express'"></v-img>
                                    <v-img :src="unionPay" width="40%" height="auto" v-if="card.card_name=='union-pay'"></v-img>
                                    <v-img :src="dinersclub" width="40%" height="auto" v-if="card.card_name=='dinners-club'"></v-img>
                                </span>
                             </v-avatar>

                            <v-list-item-content @click="input_cvv(card.pan_show_info,card.card_name,card.delete_id)" style="cursor:pointer">
                                
                                <v-list-item-subtitle class="cardPan">{{card.pan_show_info}}</v-list-item-subtitle>
                                <v-list-item-title class="cardIdentifier">{{card.card_name}}</v-list-item-title>

                            </v-list-item-content>
                           

                             <v-list-item-action>

                                 <!-- Edit Delete Icon -->
                                <v-list-item-subtitle v-if="isDisplayEditDelete" style="height:56px">
        
                                    <v-icon size="30px" left color="error" style="margin-left:10px;margin-top:10px;margin-right:-10px;color:#a9a9ac !important"
                                     @click="deleteCard(card.delete_id)">mdi-pencil-box
                                    </v-icon>

                                    <v-icon size="30px" left color="error" style="margin-left:10px;margin-top:10px;margin-right:6px" 
                                     @click="deleteCard(card.delete_id)">mdi-delete
                                    </v-icon>

                                </v-list-item-subtitle>

                                <!-- Input CVV part -->
                                <v-list-item-subtitle v-if="isDisplayCVV" style="height:56px">
                                    <v-text-field
                                        v-model="cvv_pin.one"
                                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                        :rules="cvvRules"
                                        autocomplete="off"
                                        outlined
                                        required
                                        maxlength="4"
                                        placeholder="CVV/CVC"
                                        append-icon="mdi-credit-card"
                                        style="width:120px"
                                        @blur="CheckState()"
                                    >
                                    </v-text-field>
                                </v-list-item-subtitle>


                            </v-list-item-action>
                            
                        </v-list-item>
                         <v-divider></v-divider>
                    </v-list>
                    </v-card-text>

                    <v-card-actions style="background-color:#f2f2f2" @click="add_new_card()">
                        <v-list-item>
                            <v-icon size="35px" left class="add_card_icon">
                                mdi-credit-card-plus
                            </v-icon>
                            <v-list-item-content style="background-color:#f2f2f2">
                                <v-list-item-title class="listItems">Add New Card</v-list-item-title>
                                <v-list-item-subtitle class="listItems">Visa or Mastercard Only</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-actions>

                </v-card>
            <!-- </v-dialog> -->
        </v-row>

    </div>
</template>

<script>
const pg_url = require('../../pg_url');
import axios from "axios"
export default {
    
    props: ['sp_amount','is_emi_visible','store_id'],
    data: () => ({
       
         base_url: pg_url.base_url,
           
        dialog: false,
        saved_cards:true,
        cvv_section:false,

        ld:false,
        loading_dialog:false,
        cvv_pin:{
            one:"",
        },
        cardList:"",
        card_name:"",
        card_number:"",
        month_year:"",
        paynow_from_saved_card_name:"",
        delete_id:"",

        editedItem:{
            emi: "",
        },

        emi_options:[],

         cvvRules:[
            v => !!v || "CVV/CVC is required",
            v=> /^[0-9]{3,4}$/.test(v) ||"CVV/CVC must be at least 3 digits long"
        ],


        default_card_image: require("../assets/logos/master.png"),
        masterCard: require("../assets/logos/master.png"),
        visaCard: require("../assets/logos/visa.png"),
        amex: require("../assets/logos/amex.png"),
        unionPay:require("../assets/logos/unionpay.png"),
        dinersclub:require("../assets/logos/diners_club.png"),


        isDisplayEditDelete:true,
        isDisplayCVV:false,

    }),

    mounted(){
        this.setEMI(this.sp_amount);
    },

   
    methods:{

        CheckState(){
            var input_cvv_number = this.cvv_pin.one;
            if(input_cvv_number===""){
                this.isDisplayEditDelete = true;
                this.isDisplayCVV = false;
            }
        },
        
        getSavedCardList(){
            this.cardList = "";
            var token = this.$route.query.token;
            var mobile_no = localStorage.getItem('mobile_no');

            const options = {
                method: 'POST',
                url: this.base_url+'card-info-show',
                headers: {
                    Authorization: 'Bearer '+token
                },
                data:{
                    mobile_no
                }
            };

          axios.request(options).then(response =>{
              //console.log(response.data);
            if(response.data.message == 0){
               //console.log("Something went wrong.");
            }
            else{
               this.cardList = response.data;
            }
           
          }).catch(error =>{
             console.log(error);
          });


        },

        show_saved_cards(){
            this.dialog =true;
            this.getSavedCardList();
        },

        input_cvv(card_number,card_name,delete_id){

            this.isDisplayEditDelete = false;
            this.isDisplayCVV = true;
            
            // var value = card_number;
            // var amex_char = value.substring(0,2);
            // let first_digit = value.charAt(0);
            // let second_digit = value.charAt(1)

            // if(amex_char==34 || amex_char ==37 ){
            //   this.default_card_image=this.amex;
            // }

            // if(first_digit== 3 && (second_digit ==0 || second_digit==6 || second_digit==8)){
            //     this.default_card_image= this.dinersclub;
            // }

            // if(first_digit==4){
            //   this.default_card_image=this.visaCard;
            // }

            // if((first_digit==5 && (second_digit>=1 && second_digit<=5)) || 
            // (first_digit==2 && (second_digit>=2 && second_digit<=7))){
            //   this.default_card_image=this.masterCard;
            // }

            // if(first_digit==6){
            //   this.default_card_image=this.unionPay;
            // }
            // this.card_number = card_number;
            // this.card_name = card_name;
            // this.delete_id = delete_id;
            // this.saved_cards =false;
            // this.cvv_section = true;
        },

       
        add_new_card(){
          this.$emit("card_event")
        },

        close_cvv(){
            this.cvv_section = false;
            this.saved_cards =true;
        },

        paynow_from_saved_card(){
            let valid= this.$refs.form_saved_card.validate();
            if(valid){
                this.ld=true;
                this.loading_dialog=true;
                var token = this.$route.query.token;
                var orderid = this.$route.query.order_id;
                var amt = this.sp_amount;
                var mobile_no = localStorage.getItem('mobile_no');
                var card_number = this.card_number;
                var delete_id =this.delete_id;
                var value = card_number;
                var amex_char = value.substring(0,2);
                let first_digit = value.charAt(0);
                let second_digit = value.charAt(1)


                var emi_str = this.editedItem.emi;
                var wordpos = emi_str.search("months");
                var emi_month = emi_str.substring(0, wordpos-1);
                var emi_amt = emi_str.substring(wordpos+7, emi_str.length);

                var store_id = this.store_id;

                if(amex_char==34 || amex_char ==37 ){
                     this.paynow_from_saved_card_name="american-express";
                }

                if(first_digit== 3 && (second_digit ==0 || second_digit==6 || second_digit==8)){
                    this.paynow_from_saved_card_name = "dinners-club";
                }

                if(first_digit==4){
                    this.paynow_from_saved_card_name = "brac-visa";
                }

                if((first_digit==5 && (second_digit>=1 && second_digit<=5)) || 
                (first_digit==2 && (second_digit>=2 && second_digit<=7))){
                    this.paynow_from_saved_card_name="brac-master";
                }

                if(first_digit==6){
                     this.paynow_from_saved_card_name="union-pay";
                }

                var card_name = this.paynow_from_saved_card_name;
                
                 const options = {
                    method: 'POST',
                    url: this.base_url+'card-delete-info',
                    
                    headers: {
                        Authorization: 'Bearer '+token
                    },
                    data: {
                        card_name,
                        orderid,
                        amt,
                        mobile_no,
                        delete_id,
                        store_id,
                        emi_month,
                        emi_amt,
                    },   
                    
                    };
                    axios.request(options).then(response =>{
                    if(response.data.url){
                        window.location.href = response.data.url;
                    }else{
                        window.location.href = response.data;
                    }
                
                }).catch(error =>{
                    console.log(error);
                }); 
             }
        },

        deleteCard(delete_id){
            var token = this.$route.query.token;
            const options = {
            method: 'POST',
            url: this.base_url+'card-delete',

            headers: {
                Authorization: 'Bearer '+token
            },
            data: {
                delete_id
            },
            
            };
            axios.request(options).then(response =>{
                if(response.data.message == 'Card Deleted'){
                    console.log("Card Deleted Successfully");
                }
        
            }).catch(error =>{
                console.log(error);
            });

            
        },

        setEMI(amount){

            var setemi_options = [];
            var i;
            for (i = 1; i <=5; i++) {
                if(i==5){
                setemi_options.push({
                id: 5,
                option_val:'18 months -BDT '+(amount/18).toFixed(2)+ '/Month' ,
                select_val:'18 months '+(amount/18).toFixed(2),
                });
                }
                else{
                setemi_options.push({
                    id: i*3,
                    option_val: i*3+' months -BDT '+(amount/(i*3)).toFixed(2)+ '/Month' ,
                    select_val:i*3+' months '+(amount/(i*3)).toFixed(2),
                });
                }
            }
            this.emi_options = setemi_options;

        },
    }
}
</script>

<style scoped>
    .FastPayContainer{
        border:1px solid #e5d7d7;
        height: 64px;
        background-color:#f2f2f2 !important;
        margin-left:10px !important;
        margin-right: 12px !important;
    }

    .listItems{
        padding-left: 10px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .listItems2{
        padding-left: 10px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-size: 18px;
        
    }

    .listItems3{
        padding-left: 10px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-size: 18px;
    }

    .initialBtn{
        padding-left: 30px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .add_card_icon{
        margin-left: 17px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .cvv_style{
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-top: 30px;
    }
    .pin_style{
        font-size:25px !important;
        color: black !important;
        text-align: center !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif !important;
    }
     .centered-input >>> input {
      text-align: center
    }
    .emiDisplaytext{
    font-size:12px !important;
  }

  .cardPan{
    padding-left: 10px !important;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-weight:bolder;
    font-size:16px !important;
    color:rgb(88, 89, 93) !important;
  }

  .cardIdentifier{
    padding-left: 10px !important;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    text-transform: capitalize;
    font-size:14px !important;
    color:#48494c !important;
  }


</style>
