<template>
    <div>
        <v-container fluid pa-0>
            <v-row align="center" justify="center" style="height:100vh" dense>
                <v-col cols="12" lg="6" md="6" class="fill-height d-flex flex-column justify-center align-center">
                    <v-card elevation="4" style="border-radius:10px">

                        <v-list style="background-color:#0f9750">
                            <v-list-item>
                                <v-list-item-action>
                                    <v-list-item-title class="unauthorized">Authorization Problem</v-list-item-title>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>

                        <v-card-text>
                            <v-card-subtitle class="title_content">Authorization problem occurs for the following reasons</v-card-subtitle>
                            <v-list-item style="margin-top:-15px">
                                <v-list-item-content>
                                    <v-list-item-title class="unauthorized_text"><span> &#8226;</span> Token has expired.</v-list-item-title>
                                    <v-list-item-title class="unauthorized_text"><span> &#8226;</span> Malformed token or Invalid token.</v-list-item-title>
                                    <v-list-item-title class="unauthorized_text"><span> &#8226;</span> Something went wrong.</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>

                       
                        <!-- <div style="margin-top:20px;margin-bottom:10px;padding-left:5px;padding-right:5px">
                            <v-row justify="center">
                            <v-col md="8" sm="12" xs="12">
                                <p style="font-size:14px;margin-bottom:7px;text-align:center"> 
                                    <span @click="backToMerchantSite()" style="text-decoration: underline;color:#0f9750;cursor:pointer;">Click Here
                                    </span>to return back merchant site</p>
                            </v-col>
                            </v-row>
                        </div>
                         -->
                    </v-card>

                </v-col> 
            </v-row>
        </v-container>
    </div>
</template>


<style scoped>
    .unauthorized{
        font-size: 22px !important;
        color:#fff;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-style:normal;
    }
    .unauthorized_text{
        font-size: 16px !important;
        color: #000000;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-style:normal;
    }
    .title_content{
        font-size: 20px !important;
        color: #000000;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-style:normal;
        margin-top:-15px;
    }
</style>


<script>

export default {
    
    props: {

        // trxn_sp_orderid: {
        //     default: "",
        //     type: String
        // }
    },
    
    data: () => ({
       
       //data variables and objects will be here
    }),

    methods:{

        // backToMerchantSite(){
        //     console.log(this.trxn_sp_orderid);
        // }

    }
}
</script>